// import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import {
  Grid, Button, Chip, TextField, InputAdornment,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns-tz';
import { formatDate } from '../../lib/formatDate';
import getPayout from '../../lib/getPayout';
import currencyFormat from '../../lib/currencyFormat';
import ApiClientInstance from '../../clients/api';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { Chase } from '../Loaders';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';

const timeZoneAbbreviation = format(new Date(), 'z');

const Team = ({
  logo, name, moneyline, type, record, onClick, selectedTeam, hasStarted, canBet,
}) => (
  <Grid item xs={4}>
    <div className='flex flex-col justify-center items-center'>
      <span className='font-roboto text-xs uppercase font-bold opacity-80'>{type}</span>
      <button className='tku-no-select relative' type='button' onClick={onClick}>
        <img style={{ height: '80px' }} src={logo} alt='logo' />
      </button>
      <button className='tku-no-select relative flex flex-col justify-center items-center' type='button' onClick={onClick}>
        <span className='font-roboto text-md font-bold px-2'>{name}</span>
        <span className='font-roboto text-sm'>{record}</span>
      </button>
      { (!hasStarted && !!moneyline && canBet) && <CheckCircleIcon color={selectedTeam ? 'success' : 'disabled'} /> }

      {!!moneyline && (
      <span className='font-bold font-roboto flex items-center text-sm mt-2 mb-2'>
        <img className='inline mr-1' src='/assets/brand/2kumt.svg' style={{ height: '20px' }} alt='mt icon' />
        {moneyline}
      </span>
      ) }
    </div>
  </Grid>
);

const GameStatus = ({ game }) => {
  const {
    hasStarted, currentTime, awayScore, homeScore, completed, startTime, location, home, away,
  } = game;
  const score = (
    <Grid container>
      <Grid className='flex justify-start' item xs={6}><span className='font-bold text-md'>{awayScore}</span></Grid>
      <Grid className='flex justify-end' item xs={6}><span className='font-bold text-md'>{homeScore}</span></Grid>
    </Grid>
  );
  if (!hasStarted) {
    return (
      <Grid item xs={4} className='items-center justify-center flex !flex-col font-roboto'>
        <Chip label='SCHEDULED' className='my-2' size='small' color='success' />
        <span className='text-sm font-bold block'>{`${formatDate(new Date(startTime), 'h:mm bb', { timeZone: 'America/New_York' })} ${timeZoneAbbreviation}`}</span>
        <span className='text-xs block text-center'>{location}</span>
      </Grid>
    );
  }
  if (hasStarted && !completed) {
    return (
      <Grid item xs={4} className='items-center justify-center flex !flex-col font-roboto'>
        <Chip label='LIVE' className='my-2' size='small' color='secondary' />
        <span className='text-md mb-2'>{currentTime}</span>
        {score}
      </Grid>
    );
  }
  if (completed) {
    return (
      <Grid item xs={4} className='items-center justify-center flex !flex-col font-roboto'>
        <Chip label='FINAL' className='my-2' size='small' color='primary' />
        <span className='text-sm'>{formatDate(new Date(startTime), 'M/d/yy')}</span>
        <span className='text-xs block text-center mb-2'>{location}</span>
        {score}
      </Grid>
    );
  }
  return null;
};

const GameCard = ({
  game,
  hideBetting,
  wrapperClasses,
  containerClasses,
}) => {
  const { data: user, setData: setUser } = useAuthenticatedUser();
  const [team, setTeam] = useState(null);
  const [betAmount, setBetAmount] = useState(0);
  const [displayBet, setDisplayBet] = useState('');
  const [moneyline, setMoneyline] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const { currency, username } = user || {};
  const { hasStarted } = game;

  const clearBet = useCallback(() => {
    setTeam(null);
    setBetAmount(0);
    setDisplayBet('');
    setMoneyline(null);
  }, []);

  const placeBet = useCallback(async () => {
    const body = { amount: betAmount, team, game: game._id };
    setIsSubmitting(true);
    const data = await ApiClientInstance.sendRequest({
      path: '/bet',
      method: 'post',
      data: body,
      catchError: true,
      snackbarError: 'errorMessage',
    });
    if (data.success) {
      setUser({ ...user, currency: data.data.currencyBalance });
      clearBet();
      addSnackbar({ message: 'Bet successfully placed !', color: 'primary' });
    }
    setIsSubmitting(false);
  }, [team, betAmount, user]);

  const selectHome = useCallback(() => {
    if (team === game.home._id) return setTeam(null);
    setTeam(game.home._id);
    setMoneyline(game.moneylineHome);
  }, [team]);

  const selectAway = useCallback(() => {
    if (team === game.away._id) return setTeam(null);
    setTeam(game.away._id);
    setMoneyline(game.moneylineAway);
  }, [team]);

  const handleChange = useCallback((e) => {
    const rawValue = e.target.value.replace(/,/gi, '');
    if (!rawValue) {
      setBetAmount(0);
      setDisplayBet('');
      setBetAmount(0);
      return;
    }
    const int = parseInt(rawValue, 10);
    if (Number.isNaN(int)) return;
    if (int > currency) {
      setBetAmount(currency);
      setDisplayBet(currencyFormat(currency));
      return;
    }
    setBetAmount(int);
    setDisplayBet(currencyFormat(int));
  }, [currency]);

  useEffect(() => {
    if (!team) setMoneyline(null);
  }, [team]);

  const meetsMinBetReq = !!betAmount && betAmount > 99;
  const payoutEnabled = (moneyline && meetsMinBetReq && !!currency);
  const authenticated = !!username;
  const canBet = !hideBetting && !game.hasStarted && !!game.moneylineAway && !!game.moneylineHome && new Date(game.startTime).valueOf() > new Date().valueOf();

  return (
    <div className={wrapperClasses || 'text-primary-text pl-1 pr-2 py-1 rounded'}>
      <div className={`${containerClasses || 'bg-s01dp'} ${!currency ? 'tku-no-select tku-no-pointer-events' : ''} rounded`}>
        <Grid className='py-2 items-center !mt-0 !mb-0' spacing={0} container>
          <Team canBet={canBet && authenticated} hasStarted={hasStarted} selectedTeam={team === game.away._id} onClick={selectAway} record={game.awayRecord} logo={game.away.logos.main} name={game.away.name} moneyline={game.moneylineAway} type='away' />
          <GameStatus game={game} />
          <Team canBet={canBet && authenticated} hasStarted={hasStarted} selectedTeam={team === game.home._id} onClick={selectHome} record={game.homeRecord} logo={game.home.logos.main} name={game.home.name} moneyline={game.moneylineHome} type='home' />
        </Grid>
        { (canBet && !!currency) && (
        <div className='flex flex-col justify-center px-8 mt-2 pb-4'>
          <div className='my-2 flex items-center justify-between'>
            <TextField
              disabled={!currency}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img className='inline mr-1' src='/assets/brand/2kumt.svg' style={{ height: '20px' }} alt='mt icon' />
                  </InputAdornment>
                ),
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                pattern: '[0-9]*',
                inputMode: 'numeric',
              }}
              onChange={handleChange}
              label='bet amount (min 100)'
              value={displayBet}
              sx={{ maxWidth: '170px' }}
              step='1'
              size='small'
              color='primaryLight'
              type='text'
            />
            <div className={`justify-end items-end text-sm flex flex-col ml-4 font-bold ${!payoutEnabled ? ' opacity-40' : ''}`}>
              <span>payout</span>
              <span className='flex items-center mt-1 justify-end whitespace-nowrap'>
                <img className='inline mr-1' src='/assets/brand/2kumt.svg' style={{ height: '20px' }} alt='mt icon' />
                { payoutEnabled ? currencyFormat(getPayout(betAmount, moneyline) + betAmount) : '----' }
              </span>
            </div>
          </div>
          <div className='flex space-x-4 mt-2'>
            <Button disabled={(!team && !betAmount) || isSubmitting} onClick={clearBet} fullWidth color='secondaryLight' variant='outlined'>Clear</Button>
            <Button
              disabled={!team || !meetsMinBetReq || !currency || isSubmitting}
              onClick={placeBet}
              fullWidth
              variant='contained'
            >
              { isSubmitting ? <Chase full={false} fixed={false} chaseStyle={{ height: '18px', width: '18px' }} /> : 'Place Bet'}

            </Button>
          </div>
        </div>
        ) }
        { (canBet && !currency && authenticated) && (
        <div className='px-5 flex flex-col items-center font-roboto space-y-2 text-sm pb-2'>
          <span>
            {"Uh oh. Looks like you're out of "}
            <img className='inline mr-1' src='/assets/brand/2kumt.svg' style={{ height: '20px' }} alt='mt icon' />
          </span>
        </div>
        ) }
      </div>
    </div>
  );
};

export default GameCard;
