import { getRecoil, setRecoil } from 'recoil-nexus';
import { BaseModel } from '../BaseModel';
import { UserModel } from '../user/model';
import { verifyUser } from './api';
import state from './atom';

export class AuthenticatedUserModel extends BaseModel {
  id = 'authenticatedUser';
  constructor(id) {
    const request = () => AuthenticatedUserModel.verifyUser(id);
    super(id, state, request);
    this.id = id;
  }

  static setUser(id, data) {
    if (!id) return;
    return setRecoil(state, prev => ({ ...prev, data }));
  }

  static getUser(id) {
    return getRecoil(state(id)).data;
  }

  static verifyUser(id) {
    // const token = Cookies.get(SiteCookies.AuthKey);
    // if (!token) {
    //   return {
    //     success: false,
    //     message: 'No token found',
    //   };
    // }
    return verifyUser(id);
  }

  // onReload() {
  //   console.log('loading auth user');
  // }

  onSetState(authenticatedUser, prevAuthenticatedUser) {
    if (authenticatedUser?.data?._id) {
      UserModel.setUser(authenticatedUser.data._id, authenticatedUser.data);
    }
  }
}
