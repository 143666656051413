import io from 'socket.io-client';
import { setRecoil } from 'recoil-nexus';
import { nanoid } from 'nanoid';
import Cookies from './cookies';
import { SiteCookies } from '../lib/constants';
import { connectionIdSelector } from '../models/settings/selectors';
import socketRoomsState from '../models/socketRooms/atom';

class SocketIOClient {
  constructor() {
    this.isConnected = false;
    this.hasAttachedConnect = false;
    this.hasAttachedReconnect = false;
    this.isDisconnected = false;
    this.connectionPromise = null;
    this.listeners = [];
    this.rooms = [];
    this.connectionString = process.env.REACT_APP_API_BASE;
    this.socket = io(this.connectionString, {
      withCredentials: true,
      transports: ['websocket'],
      auth: { token: Cookies.get(SiteCookies.AuthKey) },
    });
  }

  connect = async () => {
    // first connection
    if (!this.connectionPromise && !this.hasAttachedConnect) {
      this.hasAttachedConnect = true;
      this.connectionPromise = new Promise((res) => {
        this.socket.on('connect', () => {
          this.isConnected = true;
          setRecoil(connectionIdSelector, nanoid(8));
          res(true);
        });
      });
    }
    return this.connectionPromise;
  };

  waitForConnect = async () => this.connect();

  emit = async (eventName, data = {}) => {
    await this.waitForConnect();
    this.socket.emit(eventName, data);
  };

  addToListeners = (listenerObject) => this.listeners.push(listenerObject);

  removeFromListeners = id => {
    this.listeners = this.listeners.filter(l => l.id !== id);
  };

  isInRoom = (path, room) => this.rooms.includes(`${path}/${room}`);

  addToRooms = (path, room) => {
    const roomStr = `${path}/${room}`;
    if (this.rooms.includes(roomStr)) return;
    this.rooms.push(roomStr);
    setRecoil(socketRoomsState, prev => [...prev, roomStr]);
  };

  removeFromRooms = (path, room) => {
    const fullRoom = `${path}/${room}`;
    this.rooms = this.rooms.filter(r => r !== fullRoom);
    setRecoil(socketRoomsState, prev => prev.filter(r => r !== fullRoom));
  };

  logout = () => this.emit('user/logout');

  join = async (path, room) => {
    await this.waitForConnect();
    this.emit(`room/join/${path}`, { room });
    this.addToRooms(path, room);
  };

  joinUser = async (username) => {
    await this.waitForConnect();
    this.join('user', username);
  };

  leave = async (path, room) => {
    await this.waitForConnect();
    if (this.isInRoom(path, room)) {
      this.emit(`room/leave/${path}`, { room });
      this.removeFromRooms(path, room);
    }
  };

  on = async (eventName, handler) => {
    await this.waitForConnect();
    this.socket.on(eventName, handler);
  };

  off = async (eventName, handler) => {
    await this.waitForConnect();
    this.socket.off(eventName, handler);
  };

  logRequestor = () => this.emit('user/logRequestor');

  checkConnection = () => console.log('isConnected', this.isConnected);
}

const SocketClientInstance = new SocketIOClient();
SocketClientInstance.connect();

export default SocketClientInstance;
